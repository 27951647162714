.fiba-panel-header {
  height: 40px;
  margin: 0;
  padding: 0 0 0 10px;
  background-color: transparent;
  color: #6f6f6f;
}

.fiba-panel-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0 0 1.25rem 15px;
}

.fiba-panel-disabled {
  color: #6c757d;
}

.title-container {
  padding: 10px 0 0 0;
  margin: 0;
}

.title {
  flex: 1;
  margin-left: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  border-bottom: solid 1px #ebebeb;
}

.title p {
  margin-bottom: 0px;
  // margin-left: 0px;
  width: 100%;
  position: relative;
  font-weight: 600;
}

.title p.content:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-left: 10px;
  margin-top: 8px;
}

.title p.empty:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-left: 0;
  margin-top: 8px;
}

.icon {
  padding: 3px 0 0 3px;
  margin: 0;
  font-size: 13px;
}

.strong {
  font-weight: bold;
}

